<div smClickStopPropagation class="metrics-container">
  @if (goBack.observed) {
    <div class="head">
      <i (click)="goBack.emit()" data-id="Back button" class="al-icon sm-md al-ico-back pointer m-auto" smClickStopPropagation></i>
      <h3>SELECT METRIC TO DISPLAY</h3>
    </div>
  }
  <sm-search
    class="underline-search"
    [value]="searchText"
    [minimumChars]="0"
    [debounceTime]="0"
    placeholder="Search metric"
    (valueChanged)="searchQ($event)"
  ></sm-search>
  <div class="metrics" [class.has-title]="goBack.observed">
    @if (!filteredMetricTree && !(searchText?.length > 0)) {
      <div class="p-4 pe-none">
        <mat-spinner class="m-auto" [diameter]="32" [strokeWidth]="4" color="accent"></mat-spinner>
      </div>
    }
    @if (filteredMetricTree && filteredMetricTree.length === 0) {
      <div class="d-flex h-100">
        <div class="empty-state">No data to show</div>
      </div>
    }
    @if (enableClearSelection && !(metricsCols| isEmpty)) {
      <div class="actions pointer" (click)="clearSelection.emit()">Clear Selection</div>
    }
    @for (metric of filteredMetricTree; track metric[1][0].metric_hash) {
      <mat-expansion-panel
        [expanded]="searchText?.length > 0 || expandedMetrics[metric[1][0].metric_hash]"
        >
        <mat-expansion-panel-header
          class="py-2" expandedHeight="42px" collapsedHeight="42px"
          (click)="expandedMetrics[metric[1][0].metric_hash] = !expandedMetrics[metric[1][0].metric_hash]"
          >
          <mat-panel-title class="w-100">
            <span class="panel-title ellipsis" data-id="metricType" [smTooltip]="metric[0]" smShowTooltipIfEllipsis>{{ metric[0] }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          @for (variant of metricTree[metric[0]] | advancedFilter:searchText; track variant.variant_hash) {
            <div class="metric-expansion-content">
              @if (multiSelect) {
                <div class="variant-label">
                  <sm-checkbox-control #metricVariant
                    (formDataChanged)="!skipValueType ? toggleAllMetricsToDisplay(variant, metricVariant.formData) : toggleMetricToDisplay(variant, metricVariant.formData, 'value')"
                    [formData]="metricsCols[variant.metric_hash + variant.variant_hash] !== undefined"
                    [label]="variant.variant"
                  ></sm-checkbox-control>
                </div>
                @if (metricVariant.formData && !skipValueType) {
                  <div class="value-type">
                    <sm-checkbox-control #last label="LAST"
                      [formData]="metricsCols[variant.metric_hash + variant.variant_hash]?.includes('value')"
                      (formDataChanged)="toggleMetricToDisplay(variant, last.formData, 'value')"
                    ></sm-checkbox-control>
                    <sm-checkbox-control #min label="MIN"
                      [formData]="metricsCols[variant.metric_hash + variant.variant_hash]?.includes('min_value')"
                      (formDataChanged)="toggleMetricToDisplay(variant, min.formData, 'min_value')"
                    ></sm-checkbox-control>
                    <sm-checkbox-control #max label="MAX"
                      [formData]="metricsCols[variant.metric_hash + variant.variant_hash]?.includes('max_value')"
                      (formDataChanged)="toggleMetricToDisplay(variant, max.formData, 'max_value')"
                    ></sm-checkbox-control>
                  </div>
                }
              } @else {
                <div class="variant-label">
                  <mat-radio-button
                    class="sm"
                    #metricVariant
                    (change)="toggleAllMetricsToDisplay(variant, false)"
                    [checked]="metricsCols[variant.metric_hash + variant.variant_hash] !== undefined"
                    >{{ variant.variant }}
                  </mat-radio-button>
                </div>
                @if (metricVariant.checked) {
                  <div class="value-type">
                    <mat-radio-group
                      [ngModel]="metricsCols[variant.metric_hash + variant.variant_hash][0]"
                      (change)="toggleMetricToDisplay(variant, true, $event.value)"
                      >
                      <mat-radio-button class="sm" value="value">LAST</mat-radio-button>
                      <mat-radio-button class="sm px-4" value="min_value">MIN</mat-radio-button>
                      <mat-radio-button class="sm" value="max_value">MAX</mat-radio-button>
                    </mat-radio-group>
                  </div>
                }
              }
            </div>
          }
        </ng-template>
      </mat-expansion-panel>
    }
    @if (moreResults > 0) {
      <div class="more-results">And {{ moreResults }} more, use search to narrow selection</div>
    }
  </div>
</div>
